const routes = [
  {
    path: "/cash",
    component: () => import("@/views/Cashier/Staff"),
    key: "/cash",
    needLogin: true,
    tabbar: false,
    title: "菱镜收银台",
    cache: true,
    safeArea: true,
  },
  {
    path: "/trade",
    component: () => import("@/views/Cashier/TradeRecord"),
    key: "/trade",
    needLogin: true,
    tabbar: false,
    title: "交易记录",
    safeArea: true,
  },
  {
    path: "/cashpay",
    component: () => import("@/views/Cashier/Pay"),
    key: "/cashpay",
    needLogin: false,
    tabbar: false,
    title: "付款",
    safeArea: true,
  },
  {
    path: "/payresult",
    component: () => import("@/views/Cashier/Result"),
    key: "/payresult",
    needLogin: false,
    tabbar: false,
    title: "支付结果页",
    safeArea: true,
  },
];

export default routes;
