import { makeAutoObservable } from "mobx";
import "./config";
import {
  postAllCampus,
  getAllBranchMember,
  httpOwnSchools,
} from "@/utils/dict";

class AppInfo {
  // 类型
  allCampusDepts = []; // 校区组织架构缓存

  partCampusDepts = []; // 当前校区组织架构

  ownSchools = []; // 当前人所属的校区

  constructor() {
    makeAutoObservable(this);
  }

  async getCampusDepts({ limit = "all" }) {
    if (limit === "all") {
      if (this.allCampusDepts?.length > 0) return;
      const res = await getAllBranchMember();
      if (this.allCampusDepts?.length > 0) return;
      this.allCampusDepts = res.pickerOptions?.filtered || [];
    } else if (limit === "part") {
      if (this.partCampusDepts?.length > 0) return;
      const res = await postAllCampus();
      if (this.partCampusDepts?.length > 0) return;
      this.partCampusDepts = res || [];
    }
  }

  async getOwnSchools() {
    if (this.ownSchools?.length > 0) return;
    const res = await httpOwnSchools();
    this.ownSchools = res || [];
  }
}

export default new AppInfo();
