import request from "@/utils/request";
import { getStorage } from "@/utils/storage";

/**
 * 获取枚举数据
 * @param {*} params
 * @returns
 */
export const httpMetaDatas = (params) => {
  return request.get(`/api/v1.0/app/{app-id}/meta-data`, {
    params,
  });
};

/**
 * 根据appId查询所有分公司
 * @returns
 */
export const httpAppcorp = () => {
  return request.get(`/api/v1.0/app/{app-id}/app-corp`);
};
/**
 * 根据分公司查询校区
 * @param {id} 分公司id
 * @returns
 */
export const httpSchools = (param) => {
  return request.get(`/api/v1.0/app/{app-id}/${param.id}/app-campus`);
};
/**
 * 根据校区查询校区下所有部门的人员
 * @param {id} 校区id
 * @returns
 */
export const httpAppmembers = (param) => {
  return request.get(`/api/v1.0/app/{app-id}/dept/${param.id}/app-members`);
};

/**
 * 查询渠道列表
 * @param  {number}   id
 * @returns {array}
 */
export const httpChannelList = (id = 0) => {
  return request.get(`/api/v1.0/app/{app-id}/channel/${id}`);
};
/**
 * 查询当前用户组织架构信息 黑科技
 * @returns {}
 */
export const httpAppCorpCampus = () => {
  return request.post(`/api/v1.0/app/{app-id}/app-corp-campus`, {
    params: [+getStorage("appMemberId")],
  });
};

/** 根据oa查询员工信息
 * @param {oa} oa
 * @returns
 */
export const getStaffInfoByOa = (oa) => {
  return request.get(`/api/v1.0/app/{app-id}/app-member/oa-name/job-data`, {
    params: {
      "oa-name": oa,
    },
  });
};

/**
 * 创建通话场景
 * @param {string} callSceneCode
  "unknown", "未知"
  "customer", "客户/线索"
  "student", "学员"
 * @param {string} targetId ：客户/线索/学员 id
 * @param {string} targetPhone ：电话
 * @returns
 */
export const httpCallScene = (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/callScene`, {
    params: {
      ...payload,
    },
  });
};
/**
 * 拨打电话
 * @param {string} phone 被叫
 * @param {string} type 坐席版本写old，话池版本写new
 * @param {string} called 被叫手机号
 * @param {string} phone 透传字段（线索id）
 * @returns
 */
export const httpCallPhone = (payload) => {
  return request.get(
    `/api/v1.0/app/{app-id}/call/new/${payload?.phone}/${payload?.id}`
  );
};
/**
 * 根据关键字查询单条appCustomer
 * @param {string} keyword 手机号、线索id、客户id、ps编号
 * @returns {result}
 */
export const onCheckCustomer = (payload) => {
  return request.get(
    `/api/v1.0/app/{app-id}/app-customer/keyword/${payload.keyword}`
  );
};
/**
 * 查询全量分公司、校区、人员
 */
export const httpAllBranchMember = () => {
  return request.get(`/api/v1.0/app/{app-id}/app-corp-campus`);
};

/**
 * 上传文件
 * @param {object} formData
 * @returns
 */
export const uploadFile = (formData) => {
  return request.post(
    `/api/v1.0/thunder/upload/file?server=xd-scrm-web&path=externalProves`,
    {
      params: formData,
      config: {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    }
  );
};

/**
 * 获取JSAPI_TICKET
 * @returns
 */
export const httpGetCropTicket = () => {
  return request.get(
    `${process.env.REACT_APP_NOTAUTH_BASEURL}/api/v1.0/app/{app-id}/we-com/corp-ticket`
  );
};

// 类目管理列表
export const httpCategoryList = (payload) => {
  return request.post(`/api/v1.0/app/{app-id}/goods-categorys`, {
    params: {
      ...payload,
    },
  });
};

/**
 * 根据分公司查询校区
 * @returns
 */
export const httpGetSchools = () => {
  const appMemberId = getStorage("appMemberId");
  return request.get(
    `/api/v1.0/app/{app-id}/app-member/${appMemberId}/app-campus`
  );
};
